function updateHeader(area) {
	let fixedArea = area.getBoundingClientRect().height
	area.classList.add('affix')
	document.documentElement.style.setProperty('--headerHeight', fixedArea + 'px')
}

function footerHeight() {
	const footer = document.querySelector('footer')
	let footerArea = footer.getBoundingClientRect().height
	document.documentElement.style.setProperty('--footerHeight', footerArea + 'px')
}

{
	const fixed = document.querySelector('.fixed')
	const fixedHeader = document.querySelector('.header_banner.fixed .content')
	setTimeout( () => {
		updateHeader(fixed)
		updateHeaderBanner(fixedHeader)
		footerHeight()
	}, 500)

	let resizing

	window.addEventListener('resize', () => {
		clearTimeout(resizing)
		resizing = setTimeout( resize, 100)
	})	

	function resize() {
		footerHeight()
		updateHeader(fixed)
		updateHeaderBanner(fixedHeader)
	}
}

function updateHeaderBanner(area) {
	if(area) {
		let fixedArea = area.getBoundingClientRect().height
		document.documentElement.style.setProperty('--headerBannerHeight', fixedArea + 'px')
	}
}