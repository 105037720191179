class CaseStudies {
	constructor(details) {
		if(!details) return

		this.request = 'paged=1'
		
		this.postTarget = details.target
		this.postLoadingSpinner = details.loadingSpinner
		this.postAjaxUrl = details.url

		this.page = 1

		this.http = new XMLHttpRequest()
		
		this.init()
	}

	ajaxGet() {

		this.postTarget.classList.add('transition')
		this.postLoadingSpinner.classList.add('loading')
		this.postTarget.parentElement.classList.add('transition')
		
		let t = this
		this.http.onreadystatechange = function() {
			if(this.readyState == 4) {
				t.postTarget.innerHTML = t.http.responseText
				setTimeout(function() {
					t.postLoadingSpinner.classList.remove('loading')
					t.postTarget.classList.remove('transition')
					t.postTarget.parentElement.classList.remove('transition')
					t.postTarget.style = 'min-height: 0px'
				}, 100)
				setTimeout(function() {
					let height = t.postTarget.offsetHeight
					t.postTarget.style = 'min-height: ' + height + 'px'

					const pageLinks = document.querySelectorAll('.page_link span')
					pageLinks.forEach( (el) => {
						el.addEventListener('click', (e) => {
							let pageNo = el.parentElement.dataset.page
							t.updatePage(pageNo)
						})
					})

				}, 500)
			}
		}
		this.http.open('POST', this.postAjaxUrl, true)
		this.http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
		this.http.send(this.request)
	}

	updatePosts(scroll = true) {
		this.request = 'paged=' + this.page
		this.ajaxGet()

		if(scroll) {
			setTimeout( () => {
				scrollToHash('#' + this.postTarget.id)
			}, 500)
		}
	}

	updatePage(newPage) {
		this.page = newPage
		this.updatePosts()
	}

	init() {
		this.updatePosts()
	}
}

{
	const csGrid = document.querySelector('#studiesAjax'),
	loadingSpinnerIn = document.querySelector('#loading_spinner')

	let csDetails = ''

	if(csGrid) {
		csDetails = {
			'target' : csGrid,
			'loadingSpinner' : loadingSpinnerIn,
			'url' : '../../wp-content/themes/priority-ex/ajax/case_study.php',
		}
	}

	const case_studies = new CaseStudies(csDetails)
}
