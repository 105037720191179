jQuery('.testimonial_carousel').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 6000,
	pauseOnHover: false,
	pauseOnFocus: false,
});

jQuery('.gallery_slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows : false,
	autoplay : false,
	autoplaySpeed: 6000,
	pauseOnHover: false,
	pauseOnFocus: false,
});

const slickNav = document.querySelectorAll('.carousel-nav')
if(slickNav) {
	slickNav.forEach( (nav) => {
		nav.addEventListener("click", () => {
			let slider = nav.dataset.slick,
				dir = nav.dataset.dir
			
			let slickDir = (dir === 'prev') ? 'slickPrev' : 'slickNext'

			jQuery('.' + slider).slick(slickDir)
		})
	})
}