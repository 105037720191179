class MobileNav {
	constructor() {
		this.toggle = document.querySelector(".nav_toggle")
		this.links = document.querySelector(".navigation")
		this.dropdowns = document.querySelectorAll(".navigation li.menu-item-has-children")
		this.backBtns = document.querySelectorAll(".back")
		this.clickLinks = this.links.querySelectorAll('a:not(.dropdown-toggle)')

		this.init()
	}

	subClicks() {
		this.dropdowns.forEach(dropdown => {
			let linked = dropdown.querySelector('a')
			linked.addEventListener('click', function() {
				openChildNav(dropdown)
			} )
		})
	}

	navigate(link) {
		let url = link.href
		window.location = url;
	}

	toggleNav() {
		if(this.toggle.classList.contains('is-transitioning')) {
			return
		} else {
			this.toggle.classList.add('is-transitioning')
			this.toggle.classList.toggle('active')
			document.body.classList.toggle('noscroll')
			this.links.classList.toggle('active')
			this.dropdowns.forEach(dropdown => {
				dropdown.classList.remove('open')
			})
			var that = this
			setTimeout(function() {
				that.toggle.classList.remove('is-transitioning')
			}, 500)
		}
	}

	setupChildNav() {
		this.dropdowns.forEach(dropdown => {
			let links = dropdown.querySelector('ul'),
			dropdownID = dropdown.id
			let back_btn = document.createElement("li")
			back_btn.setAttribute('class', 'back')
			back_btn.setAttribute('data-dropid', dropdownID)
			back_btn.setAttribute('role', 'menuitem')
			links.append(back_btn)
			back_btn.innerHTML = back_btn.innerHTML + 'Back'
			this.updateBackBtns()
		})
	}

	updateBackBtns() {
		this.backBtns = document.querySelectorAll(".back")
	}

	toggleChildNav(dropdown) {
		dropdown.classList.toggle('open')
	}

	toggleChildNavBack(backBtn) {
		let backID = backBtn.dataset.dropid,
			menuItem = document.querySelector('#' + backID)
		menuItem.classList.remove('open')
	}


	onResize() {
		if(this.toggle.classList.contains('is-transitioning')) {
			return
		} else {
			this.toggle.classList.add('is-transitioning')
			this.toggle.classList.remove('active')
			document.body.classList.remove('noscroll')
			this.links.classList.remove('active')
			this.dropdowns.forEach(dropdown => {
				dropdown.classList.remove('open')
			})
			var that = this
			setTimeout(function() {
				that.toggle.classList.remove('is-transitioning')
			}, 500)
		}
	}
	
	bindAll() {
		["subClicks", "toggleNav", "toggleChildNav", "onResize"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	init() {
		this.bindAll()
		this.setupChildNav()
		this.toggle.addEventListener('click', () => {
			this.toggleNav()
		}, false)
		this.dropdowns.forEach(dropdown => {
			let link = dropdown.querySelector('a')
			let subNav = dropdown.querySelector('.subNavMobile')
			
			if(subNav) {
				subNav.addEventListener('click', (e) => {
					if(window.innerWidth < 992) {
						e.preventDefault()
					}
					this.toggleChildNav(dropdown)
				})
			}
		})
		this.backBtns.forEach(backBtn => {
			backBtn.addEventListener('mousedown', () => {
				this.toggleChildNavBack(backBtn)
			})
		})
		this.clickLinks.forEach(link => {
			link.addEventListener('click', (e) => {
				e.preventDefault()
				this.navigate(link)
				this.toggleNav()
			})
		})
		window.addEventListener('resize', this.onResize, false)
	}
}

const toggle = document.querySelector(".nav_toggle")
if(toggle) new MobileNav()

function navScroll() {
	const doc  = document.documentElement

	let curScroll
	let prevScroll = window.scrollY || doc.scrollTop
	let curDirection = 0
	let prevDirection = 0

	let header = document.querySelector('.header')
	let fixHeader = document.querySelector('.fixed')
	let noHeader = document.querySelector('.header_spacing')
	let toggled
	let threshold = 200

	if(noHeader) {
		header.classList.add('scrolled')
		exit
	}
	
	const checkScroll = function() {
		curScroll = window.scrollY || doc.scrollTop
		if(curScroll > prevScroll) {
			curDirection = 2
		} else {
			curDirection = 1
		}

		if(curScroll > threshold + 0) {
			header.classList.add('scrolled')
		} else {
			header.classList.remove('scrolled')
		}

		if(toggled) {
			prevDirection = curDirection
		}
		prevScroll = curScroll
	}

	window.addEventListener("scroll", checkScroll, { passive: false })

}

{
	navScroll()
}